
class WidgetTypeLookup{
    [key:string]:string;
}
class WidgetTypeClassNameLookup{
    [key:string]:string;
}
class WidgetType{
    public label:string;
    public value:string;
    constructor(label:string, value:string) {
        this.label=label;
        this.value=value;
    }
}
class LandingPageEditor{
    options:PageBuilderOptions;
    container:HTMLDivElement;
    formsContainer:HTMLDivElement;
    debugContainer:HTMLDivElement;
    jsonField:HTMLInputElement;
    typeLookup:WidgetTypeLookup=new WidgetTypeLookup();
    landingPage:LandingPage=new LandingPage();
    currentLandingPageRow:LandingPageRow=new LandingPageRow();
    currentRowIndex:number=0;
    widgetTypes:WidgetType[]=[];
    formElement:HTMLFormElement|null=null;
    lastDialogFormData:any={};
    constructor(options:PageBuilderOptions) {
        this.options=options;
        this.container=<HTMLDivElement>document.getElementById(options.containerId)!;
        this.formsContainer=<HTMLDivElement>document.getElementById(options.formsContainerId)!;
        this.debugContainer=<HTMLDivElement>document.getElementById(options.debugContainerId)!;
        this.jsonField=(<HTMLInputElement>document.getElementById("landing_page_json")!);
    }
    loadDebugData(){
        // not used anymore
        let landingPage=new LandingPage();
        for(let n=1;n<=3;n++) {
            let row = new LandingPageRow();
            row.name = "Row " + n;
            let widget = new ImageTextColumnLandingPageWidget();
            row.heading = "Heading" + n;
            row.marginBottomPercent = 6;
            row.marginTopPercent = 6;
            widget.centerText = false;
            widget.columns = [];
            for (let i = 1; i <= 2; i++) {
                let column = new ImageTextColumn();
                column.heading1 = "Heading " + i;
                column.image = "/images/test4k.jpg";
                widget.columns.push(column);
            }
            row.widgetType = widget.getClassName();
            row.widgets.push(widget);
            landingPage.rows.push(row);
        }
        this.jsonField.value=JSON.stringify(landingPage);
    }
    loadLookupTable(){
        let typeClassNameLookup:WidgetTypeClassNameLookup={};
        let arrWidget=[
            new ImageTextColumnLandingPageWidget(),
            new VideoLandingPageWidget()
        ];
        for(let i=0;i<arrWidget.length;i++){
            let widget=arrWidget[i];
            this.typeLookup[widget.getClassName()]=widget.getName();
            typeClassNameLookup[widget.getName()]=widget.getClassName();
        }
        let arrSort=[];
        for(let className in this.typeLookup){
            arrSort.push(this.typeLookup[className]);
        }
        arrSort.sort();
        for(let i=0;i<arrSort.length;i++){
            let s=arrSort[i];
            let widgetType=new WidgetType(s, typeClassNameLookup[s]);
            this.widgetTypes.push(widgetType);
        }
    }
    getWidgetName(widgetClassName:string){
        return this.typeLookup[widgetClassName];
    }
    loadJSON(jsonString:string):LandingPage{
        let landingPage = new LandingPage();
        try {
            let json = JSON.parse(jsonString);
            if (typeof json.rows != "undefined") {
                for (let i = 0; i < json.rows.length; i++) {
                    let row = json.rows[i];
                    let landingPageRow = new LandingPageRow();
                    for (let i in row) {
                        if (i == "widgets") {
                            for (let n = 0; n < row.widgets.length; n++) {
                                let widgetData = row.widgets[n];
                                let widget: LandingPageWidget;
                                switch (row.widgetType) {
                                    case "image-and-text-column":
                                        widget = new ImageTextColumnLandingPageWidget();
                                        break;
                                    default:
                                        throw("Invalid widget type:" + row.widgetType);
                                }
                                widget.loadData(widgetData);
                                landingPageRow.widgets.push(widget);
                            }
                        } else if (landingPageRow.hasOwnProperty(i)) {
                            landingPageRow[i] = row[i];
                        }
                    }
                    landingPage.rows.push(landingPageRow);
                }
            }
        }catch(e){
            // ignore errors
        }
        return landingPage;
    }
    render(){
        let self=this;
        // this.loadDebugData();
        this.loadLookupTable();
        this.landingPage=this.loadJSON(this.jsonField.value);
        // console.log(this.landingPage);
        let a:string[]=[];
        a.push('<table id="sortTableID" class="zidxTableList">');
        a.push('<thead><tr><th>Name</th><th>Heading</th><th>Type</th><th>Admin</th></tr></thead><tbody>');
        for(let i=0;i<this.landingPage.rows.length;i++){
            let row=this.landingPage.rows[i];
            a.push('<tr id="'+i+'">'+row.renderRowHTML(this)+'</tr>');
        }
        a.push('</tbody></table>');
        a.push('<p><a href="#" class="landingPageCreateRowLink zidxManagerSearchButton">Create Row</a></p>');

        this.container.innerHTML=a.join("\n");
        this.updateData();
        this.setupSortable();
        ZIDX.$("#landing_page_name").on("input paste change", function(e:any){
            self.updateData();
        });
        self.formsContainer.innerHTML='<div id="landingPageRowDialog" title="Row" ></div>';
        let dialogContainer=(<HTMLDivElement>document.getElementById("landingPageRowDialog")!);
        let formDialog = ZIDX.$(dialogContainer).dialog({
            autoOpen: false,
            width: "auto",
            height: "auto",
            resizable:false,
            draggable:false,
            modal: true,
            open: function( event:any, ui:any ) {
                ZIDX.$("body").css({ overflow: 'hidden' })
                // console.log("dialog create fired");
                if(self.currentRowIndex==-1) {
                    self.currentLandingPageRow=new LandingPageRow();
                }else{
                    self.currentLandingPageRow=self.landingPage.rows[self.currentRowIndex];
                }
                let formConfig=new ZIDXFormConfig();
                formConfig.action="/account/landing-pages/fake";
                formConfig.cancelURL="";
                formConfig.disableButtons=true;
                formConfig.formFields=[];
                self.currentLandingPageRow.getFormConfig(self, formConfig);
                let formHelper=new ZIDXFormHelper();
                let extraData={
                    rowIndex:self.currentRowIndex
                };
                dialogContainer.innerHTML=formHelper.getForm(formConfig, self.currentLandingPageRow, extraData);
                self.formElement=<HTMLFormElement>document.getElementById(formHelper.formId)!;
                self.lastDialogFormData=ZIDX.getFormDataByFormId(self.formElement.id);
                formHelper.setupEvents();
                location.hash = "zidxDialogOpen";
            },
            buttons: {
                "Save": function () {
                    self.saveDialog(formDialog);
                },
                Cancel: function () {
                    formDialog.dialog("close");
                }
            },
            beforeClose: function(event:any, ui:any) {
                let rs=self.hasFormDataChanged(self.formElement!, self.lastDialogFormData);
                if(rs.changed){
                    if(confirm("Are you sure you want to go back? Changes you made may not be saved.")) {
                        self.lastDialogFormData=rs.data;
                    }else{
                        return false;
                    }
                }
                if(location.hash != null && location.hash == "#zidxDialogOpen"){
                    history.back();
                    return false;
                }
                return true;
            },
            close: function () {
                ZIDX.$("body").css({ overflow: 'inherit' });
                // formElement.reset();
                //allFields.removeClass( "ui-state-error" );
            }
        });
        ZIDX.$(window).bind('hashchange', function () {
            if (location.hash == null || location.hash == "") {
                formDialog.dialog("close");
            }
        });

        function resizeDialog(){
            if(ZIDX.$(window).width()<992) {
                ZIDX.$("#landingPageRowDialog").dialog({
                    width: ZIDX.$(window).width() - 20,
                    height: ZIDX.$(window).height()-20
                });
                ZIDX.$("#landingPageRowDialog").dialog( "option", "position", { my: "center", at: "center", of: window } );
            }else{
                ZIDX.$("#landingPageRowDialog").dialog({
                    width: ZIDX.$(window).width()-50,
                    height: ZIDX.$(window).height()-50,
                });
                ZIDX.$("#landingPageRowDialog").dialog( "option", "position", { my: "center", at: "center", of: window } );
            }
        }
        ZIDX.$(window).on("clientresize resize", function() {
            resizeDialog();
        });
        resizeDialog();
        setTimeout(function(){
            resizeDialog();
        },100);

        ZIDX.$(".zidxPageActive .landingPageCreateRowLink").on("click", function(this:HTMLAnchorElement, e:MouseEvent) {
            e.preventDefault();
            // console.log("open dialog");
            self.currentRowIndex=-1;
            formDialog.dialog("open");
        });
        ZIDX.$(document).off("click", ".zidxPageActive .zidxScriptSort").on("click", ".zidxPageActive .zidxScriptSort", function(this:HTMLAnchorElement, e:MouseEvent){
            e.preventDefault();
            // let tr=self.getParentElementByType(this, "tr");
            // let id=ZIDX.$(tr).attr("id");
            // console.log("click sort:"+id);
        });
        ZIDX.$(document).off("click", ".zidxPageActive .zidxScriptEdit").on("click", ".zidxPageActive .zidxScriptEdit", function(this:HTMLAnchorElement, e:MouseEvent){
            e.preventDefault();
            let tr=self.getParentElementByType(this, "tr");
            let id=parseInt(ZIDX.$(tr).attr("id"));
            // console.log("click edit:"+id);
            self.currentRowIndex=id;
            formDialog.dialog("open");
        });
        ZIDX.$(document).off("click", ".zidxPageActive .zidxScriptDelete").on("click", ".zidxPageActive .zidxScriptDelete", function(this:HTMLAnchorElement, e:MouseEvent){
            e.preventDefault();
            if(!window.confirm("Are you sure you want to delete this row?")){
                return;
            }
            let tr=self.getParentElementByType(this, "tr");
            let id=parseInt(ZIDX.$(tr).attr("id"));
            ZIDX.$(tr).remove();
            self.updateRowIndices();
            self.setupSortable();
            self.updateData();
            // console.log("click delete:"+id, self.landingPage.rows);
        });
    }
    saveDialog(formDialog:any){
        let self=this;
        // console.log("save form");
        this.lastDialogFormData=ZIDX.getFormDataByFormId((this.formElement!).id);
        let data = ZIDX.getFormDataByFormId((this.formElement!).id);
        let uploading=parseInt(data.fileUploadingField);
        if(uploading>0){
            // console.log("Waiting for the file uploads to finish");
            setTimeout(function(){
                self.saveDialog(formDialog);
            }, 500);
            return;
        }
        this.currentLandingPageRow.saveForm(this, data);
        this.setupSortable();
        this.updateData();
        formDialog.dialog("close");
    }
    setupSortable(){
        let self=this;
        ZIDX.$("#sortTableID tbody").sortable({
            handle: '.sortTableID_handle',
            stop: function (e: any, e2: any) {
                // console.log("sortable stop fired");
                self.updateRowIndices();
                self.updateData();
            }
        });
    }
    updateData(){
        this.landingPage.name=(<HTMLInputElement>document.getElementById("landing_page_name")!).value;
        // console.log("updateDebugData", this.landingPage);
        // let a:string[]=[];
        // for(let i=0;i<this.landingPage.rows.length;i++){
        //     a.push(this.landingPage.rows[i].name);
        // }
        // this.debugContainer.innerHTML=a.join("<br>");
        this.debugContainer.innerHTML='Debug Data: Landing Page dump:<br><textarea style="width:100%; height:600px;">'+JSON.stringify(this.landingPage, null, 2)+'</textarea>';
        console.log("update json");
        this.jsonField.value=JSON.stringify(this.landingPage);
    }
    hasFormDataChanged(formObj:HTMLFormElement, cachedData:any):any{
        if(formObj.id == ""){
            return {changed:false};
        }
        let newData=ZIDX.getFormDataByFormId(formObj.id);
        let changed=false;
        for(let i in cachedData){
            if(typeof newData[i]== "undefined"){
                changed=true;
                break;
            }else if(newData[i] != cachedData[i]){
                if(i != "g-recaptcha-response" && i != "form_filling_data" && i != "form_email" && i != "form_session_id"){
                    changed=true;
                    break;
                }
            }
        }
        for(let i in newData){
            if(i != "g-recaptcha-response" && typeof cachedData[i]== "undefined"){
                changed=true;
                break;
            }
        }
        return {changed:changed, data:newData};
    }
    updateRowIndices(){
        let arrId = ZIDX.$("#sortTableID tbody").sortable("toArray");
        let rowElements=ZIDX.$("#sortTableID tbody tr");
        let rows = [];
        for (let i = 0; i < arrId.length; i++) {
            let currentId=parseInt(arrId[i]);
            rowElements[i].id=i;
            rows.push(this.landingPage.rows[currentId]);
        }
        this.landingPage.rows=rows;
        this.updateData();
        // console.log("New row order", self.landingPage.rows);
    }
    getParentElementByType(obj:any, type:string) {
        let i = 0;
        while (true) {
            i++;
            if (obj.tagName.toLowerCase() == type) {
                return obj;
            }
            if (obj.parentNode != null) {
                obj = obj.parentNode;
            }
            if (i > 50) {
                alert('infinite loop. invalid table html structure');
                return false;
            }
        }
    }
}